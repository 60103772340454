
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404 - Page Not found" />
    <section className="container mx-auto p-5 flex h-screen">
      <div className="m-auto">
        <p className="text-2xl md:text-7xl">404. The page you are looking for doesn't exist.</p>
      </div>
    </section>
  </>
)

export default NotFoundPage
